import { FunctionComponent } from 'react';
import { ErrorMessage, Field } from 'formik';

type FormInputT = {
	type: 'text' | 'email' | 'number' | 'password',
	name: string,
	style?: React.CSSProperties,
	labelText?: string | null,
	className?: string
}

const defaultInputStyle = {}

const FormInput: FunctionComponent<FormInputT> = ({ name, type, className, labelText = null, style={} }) => {

	return (
		<div>
			{labelText && <p className='py-2 text-[14px] text-left'>{labelText}</p>}
			<Field type={type} name={name} className={`form-input px-5 ${className}`} style={{...defaultInputStyle, ...style }} />
			<p className='my-2 text-red text-[12px]'>
				<ErrorMessage name={name} />
			</p>
		</div>
	);
};

export default FormInput;
