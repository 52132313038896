import { PrimaryButton } from 'app-widgets/Button';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import logoSm from 'assets/images/Union.png';//'../assets/logo.svg'
import logoLg from 'assets/logo.svg'
import { ExternalRoutes } from 'app-model/config/ExternalRoutes';



type HeaderT = {
	isLoggedIn?: boolean
}
const Header: FunctionComponent<HeaderT> = () => {
	const navigate = useNavigate();
	return (
		<header className='h-[10vh] px-5 md:20 items-center flex flex-row text-white bg-black'>
			<div className='flex-none' >
				<img className='md:hidden sm:block' src={logoSm} alt="Vetiva Logo" style={{ height: '50px', width: '50px' }} onClick={() => navigate('/')} />
				<img className='hidden md:block sm:hidden' src={logoLg} alt="Vetiva Logo" onClick={() => navigate('/')} />
			</div>

			<div className='w-64 flex flex-auto flex-row justify-end'>
				<button onClick={() => navigate('/login')} className='self-center mr-2'>Sign In</button>
				<PrimaryButton onClick={() => { window.location.href = ExternalRoutes.portal }} text='Create Account' />
			</div>
		</header>
	);
};

export default Header;

