import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';

import mailSvg from 'assets/mail.svg';
import FormInput from 'app-widgets/Form/Input';
import FormSubmitInput from 'app-widgets/Form/Submit';
import { adalApiFetchJsonAdapter, endpoints } from 'adalConfig';

import 'app-layout/App.css';
import Modal from 'app-widgets/Modal';
import { useDispatch } from 'react-redux';
import { setUser } from 'app-redux/userSlice';


type LoginErrorsT = {
	customerNo?: string
}

const LoginForm: FunctionComponent<any> = () => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const navigation = useNavigate();
	const dispatch = useDispatch();

	const validateForm = (values: any) => {
		const errors: LoginErrorsT = {};
		if (!values.customerNo) errors.customerNo = 'Customer ID is Required';
		return errors;
	}

	const submitForm = async (values: any, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
		const fetchUrl = `${endpoints.cmdEndpoint}/authentication/getotp`;
		const fetchOption: RequestInit = {
			method: 'POST',
			body: JSON.stringify({ partnerNo: values.customerNo }),
		};

		const { data: result, error } = await adalApiFetchJsonAdapter<any>(fetchUrl, fetchOption);

		if (result?.status === false)
			return setFieldError('customerNo', result?.otp || 'Unable to make this request at the moment')
		if (error)
			return setFieldError('customerNo', error);


		dispatch(setUser({ customerNo: values.customerNo }));
		setSubmitting(false);
		setOpenModal(true)
	}

	return (
		<div>
			<Formik
				initialValues={{ customerNo: '' }}
				validate={validateForm}
				onSubmit={submitForm}
			>
				{({ isSubmitting }) => (
					<Form>
						<FormInput className='w-full ' labelText={'Customer ID'} type={'text'} name={'customerNo'} />
						<div className='my-10' />
						<FormSubmitInput className='w-full bg-primary py-10' text={'Proceed'} isSubmitting={isSubmitting} />
					</Form>
				)}
			</Formik>

			{openModal === true &&
				<div>Please note that we have sent a 6 digit OTP to your registered email account. Click continue to input it.</div>
			}

			{openModal === true &&
				<Modal
					title={'You\'ve got a mail!'}
					body={"Please note that we have sent a 6 digit OTP to your registered email account. Click continue to input it."}
					singleActionText={'Continue'}
					openModal={setOpenModal}
					singleActionCallback={() => navigation('/otp')}
					headerImage={mailSvg} />
			}
		</div>
	);
};

export default LoginForm;
