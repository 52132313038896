import { FunctionComponent } from 'react';

import 'app-layout/App.css';
import LoginForm from './LoginForm';
// import { decodeUserFromJWT } from 'app-helpers/jwtHelper';

const Login: FunctionComponent<any> = () => {

	return (
		<div style={{ background: 'white', width: '100%' }} className='grid md:grid-rows-1 md:grid-cols-2'>
			<div className="self-center h-full Login-left"> </div>

			<div className='px-10 md:px-20 flex flex-col justify-center Login-right'>
				<h1 className='font-medium text-5xl text-primary mb-5'>The Vetiva
					<br /> Funding Portal</h1>
				<section className='w-full md:w-3/4'>

					<LoginForm />

				</section>

			</div>
		</div>
	);
};

export default Login;

