import { Fragment, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import mailSvg from 'assets/mail.svg';
import arrowSvg from 'assets/arrow.svg';
import { useNavigate } from 'react-router';

type ModalT = {
	openModal: (open:boolean) => void;
	headerImage: any;
	title?: string| ReactNode;
	body?: string | ReactNode;
	singleActionText?: ReactNode,
	singleActionCallback?: (ev:any)=> void,
	parentOpen?: boolean
};

const Modal: FunctionComponent<ModalT> =  ({openModal, title, body, singleActionText, headerImage, singleActionCallback, parentOpen=true})  =>{
	const [open, setOpen] = useState<boolean>(true)
	const cancelButtonRef = useRef(null);
	const navigation = useNavigate();

	useEffect(
		() => {
			setOpen(parentOpen);
		}, 
		[open]
	);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={openModal}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
						<Dialog.Panel className="relative transform overflow-hidden bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
							<div className="flex flex-col items-center bg-white px-10 py-10 sm:p-6 sm:pb-4">
								<img src={headerImage || mailSvg} className="w-50" />
								<h1 className='my-3 text-3xl font-bold'>{title}</h1>
								<p>{body}</p>
							</div>
							<div className="px-4 text-center py-5 bg-yellow sm:px-6">
								<button
									type="button"
									className="text-left py-3 px-5"
									style={{borderBottom:'5px solid black'}}
									onClick={singleActionCallback}
								>
									{singleActionText}  
									<img src={arrowSvg} className='px-2' style={{display:'inline'}} />
								</button>
							</div>
						</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export default Modal;