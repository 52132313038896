import { useState, useEffect } from "react";

type timerDataT = {
	seconds: number,
	minutes: number,
	hours: number
};

function useTimer(): { time: timerDataT, resetTimer: (timer: number) => void } {
	//timer is in seconds
	const deafultTimer: timerDataT = {
		seconds: 0,
		hours: 0,
		minutes: 0
	}

	//for now we only account for seconds
	const [time, setTime] = useState<timerDataT>(deafultTimer);
	const resetTimer = (timer: number) => setTime({ ...time, seconds: timer });


	useEffect(
		() => {
			const timerID = setInterval(_ => setTime({ ...time, seconds: --time.seconds }), 1000);

			if (time.seconds <= 0) clearInterval(timerID);

			return () => {
				clearInterval(timerID);
			};
		},
		[time]
	);

	return {
		time,
		resetTimer
	};
}

export default useTimer;