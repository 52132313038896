import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import Login from 'app-screens/Login';
import Header from 'app-containers/Header';
import Content from 'app-containers/Content';
import Otp from 'app-screens/Otp';
import ProtectedRoute from './ProtectedRoute';
import { $404 } from './$404';
import MakePayment from 'app-screens/MakePayment';

function App() {
	const { user, authToken } = useSelector((state: any) => state.user);

	return (
		<Fragment>
			<Header />

			<Routes>

				<Route index={true} path='/' element={<Content wrappedComponent={<Login />} />} />

				<Route path='login' element={<Content wrappedComponent={<Login />} />} />
				<Route element={<ProtectedRoute isAllowed={user !== null || authToken !== null} redirectPath={'login'} children={undefined} />}>
					<Route path="otp" element={<Otp />} />
					<Route path='makePayment'  element={<MakePayment />} />	
				</Route>
				<Route path='*' element={<$404/>} />
			</Routes>
		</Fragment>
	);
}

export default App;
