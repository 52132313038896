import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

// import useWindowSize from 'app-framework/hooks/useWindowSize';
// import useTimer from 'app-framework/hooks/useTimer';
import paymentSuccess from 'assets/payment-success.svg'
import otpResendSvg from 'assets/otp-resend.svg'
import 'app-layout/App.css';
import otpIcon from 'assets/otp.svg'
import { SecondaryButton } from 'app-widgets/Button';
import Modal from 'app-widgets/Modal';
import { adalApiFetchJsonAdapter, endpoints } from 'adalConfig';
import { GetOtpResponse, VerifyOtpResponse } from 'app-model/httpResponse/OtpResponse';
import FormSubmitInput from 'app-widgets/Form/Submit';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserAndAuth, IUserSlice, setAuth } from 'app-redux/userSlice';
import { createPaystackConfig, payWithPaystack } from 'app-helpers/paystackHelpers';
import { Loader } from 'app-widgets/Loader.tsx';
import FormInput from 'app-widgets/Form/Input';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';


const MakePayment: FunctionComponent<any> = () => {

    const [loader, setLoader] = useState({ text: '', status: false });

    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [amountValue, setAmountValue] = useState<number>(0);

    const dispatch = useDispatch();
    let { authToken, user } = useSelector((state: any) => state.user as IUserSlice);

    /**
     *  - Close the loader whenever there's any Modal in Display.
     *  - Modal are for completed/finished state
     *  - Loaders are to show progress/activity  
     **/
    useEffect(() => {
        if (openSuccessModal === true)
            setLoader({ ...loader, status: false });
    },
        [openSuccessModal]
    );

    useEffect(() => {
        window.onpopstate = () => {
            prompt('You have about to leave this page. Are you sure?', 'Yes');
        }
    }, []);

    const configurePayment = (email: string, amount: number, callback: (ref: string, status: string) => void) => {
        const paystackConfig = createPaystackConfig(
            email,
            'Fund your Vetiva Account',
            amount * 100, //paystack takes kobo
            'NGN',
            callback,
        )

        payWithPaystack(paystackConfig)
    }

    const balancePaymentWithBackend = async (ref: string) => {
        const fetchUrl = `${endpoints.cmdEndpoint}/payment/verifypayment`;
        const fetchOption = {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + authToken },
            body: JSON.stringify({
                verifierCode: window.payConfig.paystack_processorcode,
                referenceNumber: ref
            }),
        };

        return await adalApiFetchJsonAdapter<any>(fetchUrl, fetchOption);
    }




    return (
        <div className='flex bg-white w-full flex-col items-center justify-start'>
            <div className="text-center my-5 py-5 w-full flex flex-col items-center">
                <img src={otpIcon} alt="Vetiva Otp" />
                <h2 className='my-0 text-[30px] font-bold'>Make Payment</h2>
                <p className='text-primary py-5'>Hi {user?.given_name}, How much would you like to fund?</p>

                <Formik
                    initialValues={{ amountValue: 0 }}
                    validationSchema={
                        Yup.object({
                            amountValue: Yup.number()
                                .min(1000, 'Amount must be greater than 1000 NGN')
                                .required('Required')
                        })
                    }
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        setLoader({ text: 'Setting up your payment...', status: true })
                        configurePayment(user?.email as string, values.amountValue, async (ref, status) => {

                            //send ref to backend to balance payment'
                            if (status === "SUCCESS") {
                                const { data, error } = await balancePaymentWithBackend(ref);

                                if (error) alert("Unable to complete your payment at the moment");
                                if (!error && data) {
                                    setOpenSuccessModal(true);
                                    setAmountValue(values.amountValue)
                                }
                            }

                            setLoader({ ...loader, status: false })
                            setSubmitting(false);


                        })
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form className='w-5/6 md:w-1/3'>
                            <FormInput className='w-full' labelText={'Amount (in NGN)'} type={'number'} name={'amountValue'} />
                            <div className='my-5 flex justify-center w-full'>
                                <SecondaryButton onClick={() => dispatch(clearUserAndAuth())} text='Exit' className='mr-1 ml-0 w-1/2' />
                                <FormSubmitInput isSubmitting={isSubmitting} className='bg-primary ml-1  w-1/2' text={'Fund Now'} />
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>


            {loader.status === true && <Loader header={loader.text} />}


            {openSuccessModal && <Modal
                title={"Transaction Successful"}
                body={`Your account has been funded with NGN${amountValue}`}
                singleActionText={'Return to homepage'}
                singleActionCallback={() => {
                    setOpenSuccessModal(false)
                    dispatch(clearUserAndAuth())
                }}
                openModal={setOpenSuccessModal}
                headerImage={paymentSuccess} />}
        </div>
    );
};

export default MakePayment;

