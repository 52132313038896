import { Fragment } from "react";

type ButtonT = {
    style?: React.CSSProperties,
    onClick?: () => void,
    text?: string | any,
    className?: string
}

type IconedButtonT = ButtonT & {
    icon: any,
    type: 'primary' | 'secondary' | 'none',
    iconPosition?: 'left' | 'right'
}

const PrimaryButton = ({ style, onClick, text, className }: ButtonT) => {
    return (
        <button onClick={onClick} className={`bg-primary ml-2 px-5 ${className}`} style={{ color: 'white', height: '7vh', ...style }}>
            {text || 'Text'}
        </button>
    )
}

const SecondaryButton = ({ style, onClick, text, className }: ButtonT) => {
    return (
        <button onClick={onClick} className={`bg-[#BDBDBD] mx-2 px-5 ${className}`} style={{ color: 'black', height: '7vh', ...style }}>
            {text || 'Text'}
        </button>
    )
}

const ButtonWithIcon = (params: IconedButtonT) => {
    if (params.type === 'primary') {
        return <PrimaryButton className="flex-row" text={`${params.icon} ${params.text}`} />
    } else if (params.type === 'secondary') {
        return <SecondaryButton text={`${params.icon} ${params.text}`}  />
    }

    return (
		<button className="flex font-10 flex-row gap-2 text-sm inline-block items-center">
			{params.icon} {params.text}
		</button>
	);
};

export { PrimaryButton, SecondaryButton, ButtonWithIcon };