import { Navigate, Outlet } from "react-router";


const ProtectedRoute = ({
    isAllowed,
    redirectPath = '/landing',
    children
  }:{
    isAllowed:any,
    redirectPath: string,
    children:any,
  }) => {
	if (!isAllowed) {
		return <Navigate to={redirectPath} replace={true} />;
	}

	return  <Outlet />;
};

export default ProtectedRoute