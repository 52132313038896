
import { window } from 'utils/window';
type adapt = {
	// (xx: Promise<Response>)<T>: Promise<T>;
}
// //we need this hack because the script tag in index.html doesn't seem to be working.
// import 'adal.app';
// import 'adal.host';

// console.log(window);
// console.log(window.grifAdalConfig);

export const endpoints = {
	appui: window.grifAdalConfig.appEndpoint,
	queryEndpoint: window.grifAdalConfig.queryEndpoint,
	cmdEndpoint: window.grifAdalConfig.cmdEndpoint,
};

interface IAuthResult {
	error: boolean;
	errorMsg?: string;
	accessToken?: string;
}


//TODO build authentication class - it should authenticate and cache the token, it should also refresh the token based on a sliding expiration
export const authInstance = {
	acquireTokenSilent: async (): Promise<IAuthResult> => {
		return {
			error: false,
			accessToken: "xoj"
		};
	}
}


export type RequestInitWithTimeout = RequestInit & { timeout?: number };

const defaultFetchTimeout = 8000; //time in milliseconds

const fetchWithTimeout = async (resource: string, options: RequestInitWithTimeout = {}): Promise<Response> => {
	const { timeout = defaultFetchTimeout } = options;

	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);

	const response = await fetch(resource, { ...options, signal: controller.signal });

	clearTimeout(id);

	return response;
}

const apiFetchCore = async (url: string, options: RequestInitWithTimeout): Promise<Response> => {
	const authResult = await authInstance.acquireTokenSilent();

	//TODO - respond to authResult.error

	const accessToken = authResult.accessToken;

	const headers: HeadersInit = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + accessToken,

		...((options || {}).headers || {})

	};

	//this is our own version of a deep merge accounting for possibility of a null object
	//TODO - is it necessary to compensate for a null object or should we force callers to explicitly express intent by throwing an exception when options is null?
	const finalOptions: RequestInit = {
		...(options || { method: 'GET' }),

		headers
	};

	//return await fetchWithTimeout(url, finalOptions);
	return await fetch(url, finalOptions);
}

/**
  * A function for dispatching calls to Griffin's API endpoint 
  * 
  * @param url The URL for the request being made
  * @param options  Includes the body, headers, HTTP method and other options.
  * 
  * @returns A promise that resolves to a Response object.
  */
export const adalApiFetch = async (url: string, options: RequestInitWithTimeout): Promise<Response> => {
	//TODO - test whether url starts with endpoints.bosapi

	return await apiFetchCore(url, options);
};


export const adalApiFetchJsonAdapter = async <T extends unknown>(url: string, options: RequestInitWithTimeout): Promise<{
	data?: T,
	error?: any
}> => {
	try {
		const result = await (await apiFetchCore(url, options)).json();
		return {
			data: result,
			error: result.status === false ? 'Unable to make your request at this moment' : null
		};
	} catch (error:any) {
		if (error?.name === 'TypeError' || error?.name === 'NetworkError') {
			return { error: 'A network Error Occured' };
		}
		return { error: 'Unable to make your request at this moment' }
	}

}
