import { createSlice, current } from '@reduxjs/toolkit'
export interface IUserSlice {
  user?: {
    customerNo: string | number,
    email?: string,
    given_name?: string,
    full_name?: string

  } | null;

  authToken?: string | null | Record<any, any>
}
/**
 * Redux Toolkit allows us to write "mutating" logic in reducers. It
 * doesn't actually mutate the state because it uses the Immer library,
 * which detects changes to a "draft state" and produces a brand new 
 * immutable state based off those changes.
 */

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    authToken: null,
    something: null
  } as IUserSlice,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    updateUser: (state, { payload }) => {
      console.log(payload, "trying to update user")
      state.user = { ...state.user, ...payload }
    },
    setAuth: (state, { payload }) => {
      state.authToken = payload;
      console.log(current(state), 'current state')

      return state;
    },
    clearUserAndAuth: (state) => {
      state.user = null;
      state.authToken = null
    },
    // For Testing
    doSomething: (state, { payload }) => {
      state.user = null;
      state.authToken = null
    }
  }
});
const { setUser, setAuth, updateUser, clearUserAndAuth } = userSlice.actions

export { userSlice, setUser, updateUser, setAuth, clearUserAndAuth };