import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import App from 'app-layout/App';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from 'app-redux';

const store = configureStore({
	reducer: {
		user: userSlice.reducer
	}
})
const root = createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Provider store={store}>
		<HashRouter>
			<App />
		</HashRouter>
	</Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
