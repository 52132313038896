import { Spinner } from 'app-widgets/Spinner';
import { FunctionComponent } from 'react';

type FormSubmitInputT = {
    text: string,
    isSubmitting?: boolean
    style?: React.CSSProperties,
    className?: string,
    onClickProps?: Record<any, any>
}

const defaultInputButtonStyle = {
}

const FormSubmitInput: FunctionComponent<FormSubmitInputT> = ({ isSubmitting,className, text, style = {}, onClickProps = {} }) => {
    
    return (
        <button type="submit" {...onClickProps} className={`py-3 form-button text-white ${isSubmitting? 'cursor-not-allowed bg-[gray]': ''} ${className}`} style={{ ...defaultInputButtonStyle, ...style }} disabled={isSubmitting}>
            {text}
            {isSubmitting === true && <Spinner/>}
        </button>

    );
};

export default FormSubmitInput;
