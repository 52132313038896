import { CurrencyCodes } from "app-model/enums/FaEnums";
import { IPaystackConfig, PaystackCallbackStatusT } from "utils/PaystackModel";

const defaultOnClose = (ref: any) => {
	console.log('Payment transaction abandoned - ', ref);
};
const defaultOnBankTransferConfirmationPending = (ref: any) => {
	console.log('Payment awaiting confirmation; scheduled sweep will handle - ', ref);
};

export const createPaystackConfig: (email: string, label: string, amount: number, currency: CurrencyCodes, callback: (ref: string, status: PaystackCallbackStatusT) => void) => IPaystackConfig = (email, label, amount, currency, callback) => {
	//const ref = `${window.payConfig.paystack_processorcode}_${(new Date()).getTime().toString()}`;
	const ref = `${(new Date()).getTime().toString()}`;

	return {
		key: window.payConfig.paystack_publickey,
		ref: ref,
		email: email,
		label: label,
		amount: amount,
		currency: currency,
		//leave Channels
		callback: () => callback(ref, "SUCCESS"),
		onClose: ()=> callback(ref, "CLOSED"),
		onBankTransferConfirmationPending: () => defaultOnBankTransferConfirmationPending(ref),
	};
};

export const payWithPaystack: (config: IPaystackConfig) => void = (config) => {
	var handler = window.PaystackPop.setup(config);

	handler.openIframe();
};
