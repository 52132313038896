import { FunctionComponent } from 'react';

import 'app-layout/App.css';

const Content: FunctionComponent<any> = ({ children, wrappedComponent }) => {
	return (
		<div className="flex h-[90vh]">
			{wrappedComponent || children}
		</div>
	);
};

export default Content;
