import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import OtpInput from 'react-otp-input';

import useWindowSize from 'app-framework/hooks/useWindowSize';
import useTimer from 'app-framework/hooks/useTimer';
import paymentSuccess from 'assets/payment-success.svg'
import otpResendSvg from 'assets/otp-resend.svg'
import 'app-layout/App.css';
import otpIcon from 'assets/otp.svg'
import { SecondaryButton } from 'app-widgets/Button';
import Modal from 'app-widgets/Modal';
import { adalApiFetchJsonAdapter, endpoints } from 'adalConfig';
import { GetOtpResponse, VerifyOtpResponse } from 'app-model/httpResponse/OtpResponse';
import FormSubmitInput from 'app-widgets/Form/Submit';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserAndAuth, IUserSlice, setAuth, updateUser } from 'app-redux/userSlice';
import { createPaystackConfig, payWithPaystack } from 'app-helpers/paystackHelpers';
import { Loader } from 'app-widgets/Loader.tsx';
import { decodeUserFromJWT } from 'app-helpers/jwtHelper';
import { useNavigate } from 'react-router';

const selectDimensionForOtp = (windowSize: string) => ['md', 'lg', 'xlg'].includes(windowSize) ? '80px' : '50px'

const Otp: FunctionComponent<any> = () => {
	const [otp, setOtp] = useState();
	const windowSize = useWindowSize();
	const { time: { seconds }, resetTimer } = useTimer();
	const [loader, setOpenLoader] = useState({ text: '', status: false });

	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [openResendModal, setOpenResendModal] = useState(false);

	const navigation = useNavigate();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { user } = useSelector((state: any) => state.user as IUserSlice);

	
	useEffect(() => { resetTimer(100) }, []);

	useEffect(
		() => {
			if (openResendModal === true || openSuccessModal === true)
				setOpenLoader({ ...loader, status: false });
		}, 
		[openResendModal, openSuccessModal]
	);


	const submitOtp = async () => {
		setOpenLoader({ text: 'Verifying OTP', status: true })

		const fetchUrl = `${endpoints.cmdEndpoint}/authentication/verifyotp`;
		const fetchOption: RequestInit = {
			method: 'POST',
			body: JSON.stringify({ otpValue: otp }),
		};

		const { data:{status, token}, error } = await adalApiFetchJsonAdapter<any>(fetchUrl, fetchOption);
		setOpenLoader({ text: '', status: false })

		if (status === false) {
			setOpenLoader({ ...loader, status: false })
			return alert('Invalid OTP Code');
		}

		dispatch(setAuth(token));


		//set user from jwt-decode and navigate away
		const user = decodeUserFromJWT(token);
		dispatch(updateUser(user));
		navigation('/makePayment');

	}


	const resendOtp = async () => {
		const fetchUrl = `${endpoints.cmdEndpoint}/authentication/getotp`;

		setSubmitting(true);

		const fetchOption: RequestInit = {
			method: 'POST',
			body: JSON.stringify({ partnerNo: user?.customerNo }),
		};

		setSubmitting(true);

		await adalApiFetchJsonAdapter<GetOtpResponse>(fetchUrl, fetchOption);

		resetTimer(100);
		setSubmitting(false);
		setOpenResendModal(true);
	}


	return (
		<div className='flex bg-white w-full flex-col items-center justify-start'>
			<div className="text-center my-5 mx-5 py-5 sm:w-2/2 md:w-1/2 flex flex-col items-center">
				<img src={otpIcon} alt="Vetiva Otp" />
				<h2 className='my-3 text-[30px] font-bold'>Enter OTP Code</h2>
				<p className='text-primary py-5'>Dear User, please input your 6 digit OTP code below
					to begin the funding process on your Vetiva customer account</p>
				<OtpInput
					value={otp}
					onChange={setOtp}
					numInputs={6}
					inputStyle={{
						color: '#344F5D',
						width: selectDimensionForOtp(windowSize as string),
						height: selectDimensionForOtp(windowSize as string),
						margin: 'auto 5px',
						background: '#F2F2F2',
						borderRadius: '10px',
						fontSize: '20px',
						fontWeight: 'bolder',
						borderColor: '2px solid #9D7C40'
					}}
				/>
				<p className='my-5'>
					<span className='color-[#BDBDBD]'>Didn't get a code? </span>
					<button disabled={true ? seconds > 0 : false} onClick={() => resendOtp()}
						style={{
							borderBottom: `3px solid ${seconds > 0 ? 'grey' : 'black'}`,
							color: `${seconds > 0 ? 'grey' : 'black'}`
						}}>Click to resend</button>
				</p>
				<div className='my-5 flex justify-center w-full'>
					<SecondaryButton onClick={() => dispatch(clearUserAndAuth())} text='Cancel' className='md:w-52 w-1/2' />
					<FormSubmitInput isSubmitting={submitting} className='bg-primary ml-5 md:w-52 w-1/2 py-0' text={'Verify'} onClickProps={{ onClick: () => submitOtp() }} />
				</div>
				<p className='text-primary'>Expires in {seconds} secs</p>
			</div>


			{loader.status === true && <Loader header={loader.text} />}

			{openResendModal && <Modal
				title={"OTP Resent"}
				body={"Your OTP has been resent successfully to your email"}
				singleActionText={'Continue'}
				singleActionCallback={() => setOpenResendModal(false)}
				openModal={setOpenResendModal}
				headerImage={otpResendSvg} />}

		
		</div>
	);
};

export default Otp;

