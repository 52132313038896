import jwt_decode from "jwt-decode";

type UserJWTT = {
    sid: string;
    unique_name: string;
    name: string;
    given_name: string;
    family_name: string;
    email: string | any;
};

export function decodeUserFromJWT(token:string): UserJWTT{    

    const decoded:UserJWTT = jwt_decode(token as string);

    return {
        email: decoded.email,
        given_name: decoded.given_name,
        sid: decoded.sid,
        unique_name: decoded.unique_name,
        name: decoded.name,
        family_name: decoded.family_name
    }

}

